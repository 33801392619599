<template>
  <div class="media-parallax" ref="elRoot">
    <slot />
  </div>
</template>

<script setup lang="ts">
import './mediaAnimationParallax.css'

const props = defineProps<{
  inverse?: boolean
}>()

const { $gsap } = useNuxtApp()
const { pageTransitionState } = usePageTransition()

const elRoot = ref()

let elFigure: HTMLElement

onMounted(() => {
  elFigure = elRoot.value.querySelector(':scope > .media')
})

useGSAP(() => {
  $gsap.fromTo(
    elFigure,
    {
      y: () => {
        if (props.inverse) {
          return 0
        } else {
          const heightDiff = calcHeightDiff()
          return -heightDiff
        }
      },
    },
    {
      y: () => {
        if (props.inverse) {
          const heightDiff = calcHeightDiff()
          return -heightDiff
        } else {
          return 0
        }
      },
      ease: 'none',
      scrollTrigger: {
        trigger: elRoot.value,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        // markers: true,
        invalidateOnRefresh: true,
      },
    },
  )
}, elRoot)

function calcHeightDiff() {
  return elFigure
    ? elFigure.offsetHeight - (elFigure.parentElement?.offsetHeight ?? 0)
    : 0
}
</script>
